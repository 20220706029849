import { Storage } from 'app/common/enums/storage';
import { Environment } from 'app/common/interfaces';

import { defaults } from './defaults.environment';
import { localization } from './locale.en-us';

export const environment: Environment = {
    production: true,
    name: 'app-us.enquirytracker.net',
    isAllowedInvalidAppSubmission: false,
    domainServer: 'api-us.enquirytracker.net',
    apiUrl: 'https://api-us.enquirytracker.net/',
    googleMapApiKey: 'AIzaSyDNJI3S_boLJ9pkgg3gmDGk-CRRhV3cZZk',
    googleTranslateApiKey: 'AIzaSyDNJI3S_boLJ9pkgg3gmDGk-CRRhV3cZZk',
    storageType: Storage.LocalStorage,
    reCaptchaSiteKey: '6Le9a6UZAAAAAIXPcDiMqS6nml9c_oyi9YOZSrkZ',
    reCaptchaV2SiteKey: '6LfKBdIZAAAAAKwc2vzn6JP3GsX_5FmOJpBxqK2t',
    ...defaults,
    localization,
};
